(function () {
    document.addEventListener("DOMContentLoaded", function (event) {
        var mySwiper = new Swiper('.swiper-container', {
            // Optional parameters
            direction: 'horizontal',
            loop: true,
            coverflow: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true
            }
        });
        var quoteslider = new Swiper('.quotes-slider', {
            // Optional parameters
            direction: 'horizontal',
            autoplay:7000,
            loop: true,
            pagination:document.getElementById("quote-dots"),
            paginationClickable:true,
            onSlideChangeStart:function (e) {
                mySwiper.slideTo(e.activeIndex);
            }
        });
    });
})();