(function () {
    document.addEventListener("DOMContentLoaded", function (event) {
        var menu = document.getElementById("primarynav");

        window.addEventListener('scroll', function () {
            stickymenu(menu, 'expanded');
        }, false);

        menu.querySelector('.nav-toggle').addEventListener('click', toogleMenuMobile);
        menu.querySelector('.nav-toggle').addEventListener('touchend', toogleMenuMobile);
        menuMobileContent();
    });

    function stickymenu(menuelement) {
        var hamburger = document.querySelector("#primarynav .nav-toggle");
        if (!hamburger.classList.contains("is-active")) {
            if (scrollValue() >= 100) {
                menuelement.classList.add("sticky");
                menuelement.classList.add("is-animated");
            }
            if (scrollValue() < 100) {
                menuelement.classList.remove("sticky");
                menuelement.classList.remove("is-animated");
            }
        }
    }

    function toogleMenuMobile(e) {
        e.preventDefault();
        var hamburger = document.querySelector("#primarynav .nav-toggle");
        var overlay = document.getElementById("overlay");

        overlay.addEventListener('click', toogleMenuMobile);
        overlay.addEventListener('touch', toogleMenuMobile);

        var pan = document.getElementById("menumobile");

        var activeClass = "is-active";
        var active = hamburger.classList.contains(activeClass);

        if (active) {
            hamburger.classList.remove(activeClass);
            overlay.classList.remove(activeClass);
            pan.classList.remove(activeClass);
            if (scrollValue() < 100) {
                document.getElementById('primarynav').classList.remove('sticky');
            }
        } else {
            hamburger.classList.add(activeClass);
            overlay.classList.add(activeClass);
            pan.classList.add(activeClass);
            document.getElementById('primarynav').classList.add('sticky');
            menuMobileContent(); // populate menu
        }
    }

    function menuMobileContent() {
        var navigation = document.querySelectorAll("#primarynav .nav > .nav-item");
        var navigationMobile = document.querySelector("#menumobile .content");
        navigationMobile.innerHTML = "";
        for (var i = 0, nb = navigation.length; i < nb; ++i) {
            if (isHidden(navigation[i])) {
                var clone = navigation[i].cloneNode(true);
                if (clone.classList.contains("has-submenu")) {
                    clone.className = "nav-item has-submenu";
                    clone.addEventListener('touchend', toogleSubMenu);
                    clone.querySelector("input").id += "mobile";
                    clone.querySelector("label").setAttribute("for", clone.querySelector("input").id);
                } else {
                    clone.className = "nav-item";
                }
                navigationMobile.appendChild(clone);

            }
        }
    }

    function toogleSubMenu(event) {
        var el = event.currentTarget;
        var activeClass = "is-active"
        console.log(el);
        if (el.classList.contains(activeClass)) {
            el.classList.remove(activeClass);
            el.querySelector(".submenu").classList.remove('active');
        } else {
            el.classList.add(activeClass);
            el.querySelector(".submenu").classList.add('active');
        }
    }

    function isHidden(el) {
        return (el.offsetParent === null)
    }

        function scrollValue() {
            return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        }

})(window);


