(function(){
    document.addEventListener("DOMContentLoaded", function(event) {
        AOS.init();
        var svg = document.getElementById("metro");
        var cards = document.querySelectorAll("#cursus article");
        document.querySelector("#cursus .prepa").addEventListener('mouseover',function(e){
        });

        var formations = {
            prepa : {
                elements : svg.querySelectorAll('.prepa'),
                cible : cards[0],
                url: cards[0].querySelector('a.cta').getAttribute("href")
            },
            bachelor : {
                elements : svg.querySelectorAll('.bachelor'),
                cible : cards[1],
                url: cards[1].querySelector('a.cta').getAttribute("href")
            },
            mastere : {
                elements : svg.querySelectorAll('.mastere'),
                cible : cards[2],
                url: cards[2].querySelector('a.cta').getAttribute("href")
            }
        };

        for (var key in formations) {
            if (formations.hasOwnProperty(key)) {
                var obj = formations[key];
                metroLinker(obj.elements,obj.cible,obj.url);
            }
        }
    });

    function metroLinker(elements,card,url){
        var i=0;
        nb=elements.length;
        for(i;i<nb;i++){
            elements[i].addEventListener('mouseover',function(e){
                card.classList.add('is-selected');
            });
            elements[i].addEventListener('mouseleave',function(e){
                card.classList.remove('is-selected');
            });
            elements[i].addEventListener('click',function(e){
                window.location.href = url;
            });
        }
    }
})();
