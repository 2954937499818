(function () {
    document.addEventListener("DOMContentLoaded", function (event) {

        var videoBt = document.getElementById("videoBt");
        if(videoBt !== undefined || videoBt ==null){
            document.getElementById("videoBt").addEventListener('click', launchVideo);
        }

    });
    var PopInEvents = {
        CLOSE: "SUPERCLOSEEVENTNAME",
        OPEN: "SUPEROPENEVENTNAME",
        OVERLAY_ON: "SUPEREVENTOVERLAYON",
        OVERLAY_OFF: "SUPEREVENTOVERLAYON",
        WRAPPER_ON: "SUPEREVENTWRAPPERON",
        VIDEO_ENDED : "SUPEREVENTPLAYONGENDVIDEO"
    };

    function launchVideo(e) {
        e.preventDefault();
        var bt = e.currentTarget;
        var wrapper = document.getElementById("videobtWrapper");
        wrapper.style.overflow = "hidden";
        wrapper.style.maxHeight = window.getComputedStyle(wrapper).getPropertyValue('height');
        bt.classList.add('video_activ');

        var pop = new YoutubePopIn(bt.href);
        pop.wrapper.id = "youtubevideo";
        pop.wrapper.className = "popin is-debug youtube";
        pop.addEventListener(PopInEvents.OPEN,function(){
        });
        pop.addEventListener(PopInEvents.CLOSE, function (e) {
            bt.classList.remove('video_activ');
        });
        pop.init();
    }

    /** class pop youtube */
    var YoutubePopIn = function (url) {
        return {
            wrapper: document.createElement('div'),
            url: url,
            player: document.createElement('div'),
            popOverlay: document.createElement('div'),
            activeClassName: "is-active",
            closeBt : null,
            init: function () {
                this._injectApi();
                this.dispatchEvent(new CustomEvent(PopInEvents.OPEN));

                this.addEventListener(PopInEvents.OVERLAY_ON, function (e) {
                    e.stopImmediatePropagation();
                    // self ctx reference
                    e.detail._makeWrapper();
                });
                this.addEventListener(PopInEvents.WRAPPER_ON, function (e) {
                    e.stopImmediatePropagation();
                    // self ctx reference
                    e.detail._callPLayer();
                });


                this._makeOverlay();
            },
            _injectApi: function () {
                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            },
            _makeOverlay: function (div, activeClassName) {
                var overlayCLassActiv = this.activeClassName;
                // self ref
                var ctx = this;
                this.popOverlay.id = "youtube_overlay";
                this.popOverlay.className = "is-overlay is-90-black has-transition is-fixed";
                document.body.appendChild(this.popOverlay);

                this.popOverlay.addEventListener('click', function(e){
                    e.stopImmediatePropagation();
                    ctx.closePopin(e);
                });

                this.popOverlay.classList.add(overlayCLassActiv);
                this._delayFunction(function () {
                    ctx._makeCLoseBt();
                }, 500, PopInEvents.OVERLAY_ON);
            },
            _makeWrapper: function (div, activeClassName) {
                this.wrapper = document.createElement('div');
                this.wrapper.id = "youtube_wrapper";
                this.wrapper.className = "popin youtube has-transition";
                this.wrapper.classList.add(activeClassName);
                document.body.appendChild(this.wrapper);
                this.dispatchEvent(new CustomEvent(PopInEvents.WRAPPER_ON));
            },
            _makeCLoseBt: function(){
                this.closeBt = document.createElement("div");
                this.closeBt.className= 'close has-transition';
                // self ref
                var ctx = this;
                this.closeBt.addEventListener("click",function(e){
                    e.stopImmediatePropagation();
                    ctx.closePopin(e);
                });
                this.popOverlay.appendChild(this.closeBt);
            },
            _callPLayer: function () {
                this.player = new YT.Player('youtube_wrapper', {
                    height: '100%',
                    width: '100%',
                    videoId: this._getYoutubeId(this.url),
                    events: {
                        'onReady': this._playVideoReady,
                        'onStateChange': this._playerStateChange
                    }
                });
            },
            _playVideoReady: function (e) {
                this.wrapper = document.getElementById("youtube_wrapper");
                try {
                    e.target.playVideo();
                } catch (e) {
                    console.error(e);
                }
            },
            _playerStateChange: function (e) {

            },
            _delayFunction: function (fname, timer, eventName) {
                // js scope ....
                var context = this;
                setTimeout(fname, timer);
                setTimeout(function () {
                    context.dispatchEvent(new CustomEvent(eventName));
                }, timer);
            },
            _getYoutubeId:function (url) {
                var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                var match = url.match(regExp);
                return (match&&match[7].length==11)? match[7] : false;
            },
            closePopin: function (e) {
                if(e !== undefined){
                    e.stopImmediatePropagation();
                }
                var ctx = this; // super self reference trick
                this.wrapper = document.getElementById("youtube_wrapper"); // bug youtube api
                this.popOverlay.classList.remove(this.activeClassName);
                this.player.stopVideo();
                document.body.removeChild(ctx.wrapper);

                this._delayFunction(function(){
                    document.body.removeChild(ctx.popOverlay);

                },500,PopInEvents.CLOSE);
            },
            _dropPopin: function (){

            },
            addEventListener: function (eventName, callback) {
                document.addEventListener(eventName, callback);
            },
            dispatchEvent: function (event) {
                // self ref trick
                var ctx = this;
                var nEvent = new CustomEvent(event.type, {'detail': ctx});
                document.dispatchEvent(nEvent);
            }

        };
    };
})(window);